import MetaTags from "react-meta-tags";
import $ from "jquery";
import React, { Component } from "react";
import "./Home.css";
import "./Responsive.css";
import "./Footer"
import { Footer } from "./Footer";

export class Home extends Component {
  static displayName = Home.name;

  componentDidMount() {
    $(".nav-bar").toggleClass(
      "animate__animated animate__delay-3s animate__fadeInDown next"
    );
    $("header").toggleClass(
      "animate__animated animate__delay-3s animate__fadeInDown next"
    );
    $(".burger-button").toggleClass(
      "animate__animated animate__delay-3s animate__fadeInDown next"
    );
  }

  render() {
    return (
      <div className="wrapper">
        <MetaTags>
          <title>Оксана Старшова фотограф Київ - Головна</title>
          <meta name="description" content="Оксана Старшова фотограф Киев" />
          <meta property="og:image" content="/img/back2.png" />
          <meta
            property="og:title"
            content="Фотограф Старшова Оксана - Головна"
          />
          <meta
            property="og:image"
            content="ttps://starshova.net.ua/img/portfolio/pf0.jpg"
          />
        </MetaTags>

        <div className="black-cover">
          <div className="main_screen">
            <div className="container_back">
              <div className="main-header">
                <h1>PHOTOS</h1>
                <h2>BY STARSHOV V.S.</h2>
              </div>
              <div className="half">
                <div className="back1 animate__animated animate__fadeInUp animate__slow"></div>
              </div>
              <div className="half">
                <div className="back2 animate__animated animate__delay-1s animate__fadeInUp next animate__slow"></div>
              </div>
            </div>
          </div>
         {/* <Footer/>  */}
        </div>
        
      </div>
    );
  }
}
